
import { FormPage } from '@/mixins'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import { BusinessBranchOpeningTime, OpeningTimeDay } from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { InputDateTime, InputSelect, InputText } from '@/components/inputs'

@Component({
  components: {
    InputSelect,
    InputText,
    InputDateTime
  }
})
export default class OpeningHoursEditor extends Mixins(FormPage) {
  @Prop({ required: true })
  private readonly businessBranchId!: string

  @Prop({ required: true })
  private readonly value!: BusinessBranchOpeningTime[];

  @Prop({ default: false })
  private readonly vertical!: boolean;

  getDay (): OpeningTimeDay {
    if (!this.value.length) return OpeningTimeDay.Monday
    const day = this.value[this.value.length - 1].Day
    if (day === OpeningTimeDay.Sunday) return OpeningTimeDay.Monday
    else { return (day + 1 as OpeningTimeDay) }
  }

  private openingTimeDays: DropdownListItem[] = [
    { Key: OpeningTimeDay[1], Label: 'Monday', Value: OpeningTimeDay.Monday },
    { Key: OpeningTimeDay[2], Label: 'Tuesday', Value: OpeningTimeDay.Tuesday },
    { Key: OpeningTimeDay[3], Label: 'Wednesday', Value: OpeningTimeDay.Wednesday },
    { Key: OpeningTimeDay[4], Label: 'Thursday', Value: OpeningTimeDay.Thursday },
    { Key: OpeningTimeDay[5], Label: 'Friday', Value: OpeningTimeDay.Friday },
    { Key: OpeningTimeDay[6], Label: 'Saturday', Value: OpeningTimeDay.Saturday },
    { Key: OpeningTimeDay[7], Label: 'Sunday', Value: OpeningTimeDay.Sunday }
  ]

  public CreateOpeningTime (): void {
    this.loading = true
    const day = this.getDay()
    const createOpeningTime = { Day: day, OpeningTime: '08:00:00', ClosingTime: '17:00:00' }
    Services.API.Businesses.AddOpeningTime(this.businessBranchId, createOpeningTime)
      .then((returnTime) => {
        const newValue = [...this.value, returnTime]
        this.$emit('input', newValue)
      })
      .catch((error) => { this.httpError = error })
      .finally(() => { this.loading = false })
  }

  private updateDebounce: number | undefined = undefined
  UpdateOpeningTime (time: BusinessBranchOpeningTime): void {
    window.clearTimeout(this.updateDebounce)
    this.updateDebounce = window.setTimeout(() => {
      Services.API.Businesses.UpdateOpeningTime(this.businessBranchId, time)
    }, 1000)
  }

  DeleteOpeningTimes (time: BusinessBranchOpeningTime): void {
    Services.API.Businesses.DeleteOpeningTime(this.businessBranchId, time.OpeningTimeId)
      .then(() =>
      {
        const newValue = this.value
        const index = newValue.findIndex((x) => x.OpeningTimeId === time.OpeningTimeId)
        newValue.splice(index, 1)
        this.$emit('input', newValue)
      })
  }
}
