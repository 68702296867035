enum InputType {
    text = 'text',
    email = 'email',
    tel = 'tel',
    select = 'select',
    textarea = 'textarea',
    password = 'password',
    date = 'date',
    time = 'time',
    richtext = 'richtext',
    url = 'url',
    checkbox = 'checkbox',
    category = 'category'
}

export default InputType;
